function errorLogin(e, custom_msg = null) {
    var error = $(`#${e}_error`);
    error.show();
    if (!custom_msg) return error.html('Please fill out this field.');
    else return error.html(custom_msg);
}

window.loginFormSubmit = () => {
    $('#submit_login_form').submit();
}

// Saves scroll height on submit
$(document).on('click', '#submit_login', function (e) {
    e.preventDefault();
    var email = $('#email');
    var password = $('#password');

    cleanAll();
    if (email.val() == "") {
        errorLogin('email');
    } else if (!validateEmail(email.val())) {
        errorLogin('email', "Invalid email.");
    } else if (password.val() == "") {
        errorLogin('password');
    } else {
        grecaptcha.execute();
    }
    return false;
});
